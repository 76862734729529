import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import { AjaxService } from '../services/ajax.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';

interface DataRow {
  [key: string]: any;
}

export interface AcademicYear {
  id : Number;
  year : String;
}

interface ParentColumn {
  name: string;
  childColumns: string[];
  data: DataRow[];
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild('myModal') myModal: any;
  excelfile: any;
  academicYearName = new FormControl('', [Validators.required, this.academicYearValidator]);
  constructor(private _ajaxService: AjaxService, private snackbar: MatSnackBar, private _userService: UserService, private _router: Router, private modalService: NgbModal) { }
  columns: any;
  schoolNames: any = [];
  schoolIds: any = [];
  firstName: any = [];
  lastName: any = [];
  emails: any = [];
  alternateEmail: any = [];
  authProvider: any = [];
  regionsData: any = [];
  enableUsersData: boolean = true;
  uploadType: String = 'usersData';
  csipData: any = [];
  tableData: ParentColumn[] = [];
  csipDataForTable: any = [];
  enrollmentSummary: any = [];
  blueRibbonCalculator: any = [];
  linkColumns: any = [];
  columnsToCalculate: any = [];
  currentPage = 1;
  itemsPerPage = 50;
  adminUserData: any[] = [];
  schoolUserData: any[] = [];
  userTableData: any[] = [];
  userTableColumnOrder: any[] = ['Email', 'First Name', 'Last Name', 'School Id', 'Role Id', 'Alternate Email', 'Active Status'];
  elementrySchools: any[] = [];
  highSchools: any[] = [];
  academicYears: AcademicYear[] = [];
  academicYearId = 1;
  submitted = false;

  ngOnInit(): void {
    this.getAllUsers()
    this.getAcademicYears();
    this._userService.getUserDetails().subscribe((value: any) => {
      if (value != undefined && Object.keys(value).length > 0) {
        if (value.roleId != 5) {
          this._router.navigate(['/dashboard'])
        }
      }
    });
  }

  openModal() {
    this.modalService.open(this.myModal);
  }

  /**
   * @author Gopi
   * @uses to upload the excel data
   */
  uploadExcelFile(uploadType: any) {
    if (!this.excelfile) {
      this.snackbar.open("Please upload the Excel template.", "OK", { duration: 3000, panelClass: "warning-dialog" });
      return;
    }
    else {
      if (uploadType === 'usersData') {
        const schools = this.schoolNames.map((item: any, index: any) => ({ ...item, ...this.schoolIds[index] }));
        const userNames = this.firstName.map((item: any, index: any) => ({ ...item, ...this.lastName[index] }));
        const emails = userNames.map((item: any, index: any) => ({ ...item, ...this.emails[index] }));
        const authProvider = emails.map((item: any, index: any) => ({ ...item, ...this.authProvider[index] }));
        const users = authProvider.map((item: any, index: any) => ({ ...item, ...schools[index] }));
        const updatedUser = [...this.adminUserData, ...this.schoolUserData]
        let request = {
          "users": updatedUser,
          "schools": schools,
        }
        this._ajaxService.post('admin/saveExcelData', request).then((response: any) => {
          if (response.statusCode == 200) {
            this.excelfile = null;
            this.getAllUsers();
            this.snackbar.open("Excel file uploaded successfully", "OK", { duration: 3000, panelClass: "success-dialog" });
          }
          else if (response.statusCode == 400) {
            this.snackbar.open("Error while uploading excel file", "OK", { duration: 3000, panelClass: "warning-dialog" })
          }
          else if (response.statusCode == 401) {
            this.snackbar.open("Schools data cann't be empty", "OK", { duration: 3000, panelClass: "warning-dialog" })
          }
        })
      }
      else {
        const uniqueSchools = [
          ...this.elementrySchools,
          ...this.highSchools
        ].filter((school, index, self) =>
          index === self.findIndex((s) => s['School ID'] === school['School ID'])
        );
        let request = {
          'uniqueSchools' : uniqueSchools,
          'schools': [...this.elementrySchools, ...this.highSchools],
          'enrollmentSummary': this.enrollmentSummary,
          'blueRibbonCalculator': this.blueRibbonCalculator,
          'acdemicYearId' : this.academicYearId,
        }
        this._ajaxService.post('admin/saveCSIPData', request).then((response: any) => {
          if (response.statusCode == 200) {
            this.snackbar.open("Data uploaded successfully", "OK", { duration: 3000, panelClass: "success-dialog" });
            this.getSchoolData();
          }
          else {
            this.snackbar.open("Error while uploading data", "OK", { duration: 3000, panelClass: "warning-dialog" })
          }
        })
      }
    }
  }

  /**
   * @author Manjunath
   * @uses to format regional and school level user data
   */
  tansformSchoolUserData(usersData:any[]) {
    const transformedData = Object.values(usersData.reduce((acc, curr) => {
      const { Email, 'First Name': firstName, 'Last Name': lastName, 'Alternate Email': alternateEmail, ...schoolDetails } = curr;
      if (!acc[Email]) {
        acc[Email] = { Email, 'First Name': firstName, 'Last Name': lastName, 'Alternate Email': alternateEmail, SchoolDetails: [schoolDetails] };
      } else {
        acc[Email].SchoolDetails.push(schoolDetails);
      }
      return acc;
    }, {}));
    return transformedData;
  }

  academicYearValidator(control: AbstractControl): ValidationErrors | null {
    const pattern = /^[0-9]{4}-[0-9]{2}$/;
    return pattern.test(control.value) ? null : { invalidAcademicYear: true };
  }

  /**
   * @author Manjunath
   * @uses to format regional and school level user data
   */
  updateUserData(userData:any[], userColumns:any[], type:string) {
    const fomattedData:any[] = [];
    if (userData.length > 0) {
      userData.forEach((user : any) => {
        let roleId : number = type == 'admin' ? 1 : 4;
        if (user.some((cell: string | null | undefined) => cell !== undefined && cell !== null && cell !== '')) {
          const rowData : { [key : string]: unknown} = {};
        for (let i=0; i < userColumns.length; i++) {
          const colName = userColumns[i];
          const colVal = user[i];
          rowData[colName] = colVal
        }
        if (roleId == 1 && rowData['Email'] == 'hal@silverlininglearning.com') {
          roleId = 5
        }
        if (rowData['Email'] || rowData['Alternate Email']) {
          rowData['roleId'] = roleId
          fomattedData.push(rowData)
        }
        }
      })
    }
    return fomattedData
  }

  /**
   * @author Gopi
   * @uses to read the excel data
   */
  onFileChange(event: any, fileType: any) {
    const file = event.target.files[0];
    this.excelfile = file
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      if (fileType === 'usersData') {
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const superAdminData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const worksheetOne = workbook.Sheets[workbook.SheetNames[0]];
        const regionalDirectorData = XLSX.utils.sheet_to_json(worksheetOne, { header: 1 });
        const worksheetTwoData = workbook.Sheets[workbook.SheetNames[1]];
        const schoolLevelData = XLSX.utils.sheet_to_json(worksheetTwoData, { header: 1 });
        this.formatExcelData(superAdminData, 'superAdmin');
        this.adminUserData = this.updateUserData(regionalDirectorData.slice(1), regionalDirectorData[0] as string[], 'admin')
        this.schoolUserData = this.updateUserData(schoolLevelData.slice(1), schoolLevelData[0] as string[], 'principle')
      }
      else {
        let highSchoolData : any[] = [];
        let elementrySchoolData : any[] = [];
        workbook.SheetNames.forEach((sheet) => {
          if (sheet !== '') {
            const worksheet: XLSX.WorkSheet = workbook.Sheets[sheet];
            const jsonData: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true });
            const parentColumn = jsonData[0] as string[];
            const childColumn = jsonData[1] as string[];
            const dataRows: any[][] = sheet === 'Elementary School Data' ? jsonData.slice(2) : sheet === 'High School Data' ? jsonData.slice(3) : jsonData.slice(1);
            const futherChildColumns = jsonData[2] as string[];
            const results = this.extractData(dataRows, parentColumn, childColumn)
            const extractedHighSchooldata = sheet === 'High School Data' ? this.extractDataThreeRows(dataRows, parentColumn, childColumn, futherChildColumns) : null;
            if (sheet === 'Elementary School Data') elementrySchoolData = results
            if (sheet === 'High School Data' && extractedHighSchooldata) highSchoolData = extractedHighSchooldata
          }
        })
        const calculatedElementrySchool = this.calculatePercentageCellValues(elementrySchoolData)
        const calculatedHighSchool = this.calculatePercentageCellValues(highSchoolData)
        this.determineSchoolType(calculatedElementrySchool, calculatedHighSchool);
        this.getEnrollmentSummary(calculatedElementrySchool, false);
        this.getEnrollmentSummary(calculatedHighSchool, true);
        this.getBlueRibbonCal(elementrySchoolData);
      }
    };
    fileReader.readAsArrayBuffer(file);
  }

  /**
   * @author Manjunath
   * @param dataRows 
   */
  extractData(dataRows: any[], parentColumn: any[], childColumn: any[]) {
    const finalData: any[] = [];
    dataRows.forEach((school: any) => {
      let lastkey: string | null = null
      if (school.some((cell: string | null | undefined) => cell !== undefined && cell !== null && cell !== '')) {
        const response: { [key: string]: any } = {};
        for (let i = 0; i < school.length; i++) {
          if (parentColumn[i] == undefined) {
            const obj = {
              [childColumn[i]]: school[i]
            };
            if (lastkey !== null) {
              response[lastkey] = { ...response[lastkey], ...obj };
            } else {
              response[childColumn[i]] = school[i];
            }
          } else {
            if (childColumn[i] !== undefined) {
              const obj = {
                [childColumn[i]]: school[i],
              };
              response[parentColumn[i]] = obj;
              lastkey = parentColumn[i]
            } else {
              response[parentColumn[i]] = school[i];
              lastkey = parentColumn[i]
            }
          }
        }
        if (response['School ID'] != undefined && response['School ID'] != null && response['School ID'] != '') finalData.push(response)
      }
      else {
        lastkey = null;
      }
    })
    return finalData
  }

  /**
   * @author Manjunath 
   */
  extractDataThreeRows(dataRows: any[], parentColumn: any[], childColumn: any[], futherChildColumns:any[]) {
    const finalData: any[] = [];
    dataRows.forEach((school: any) => {
      let lastkey: string | null = null
      let lastChildkey: string | null = null
      if (school.some((cell: string | null | undefined) => cell !== undefined && cell !== null && cell !== '')) {
        const response: { [key: string]: any } = {};
        for (let i = 0; i < school.length; i++) {
          if (parentColumn[i] == undefined) {
            if (childColumn[i] == undefined) {
              if (futherChildColumns[i] !== undefined) {
                if (lastChildkey && lastkey) {
                  const obj = {
                    [futherChildColumns[i]] : school[i]
                  }
                  response[lastkey][lastChildkey] = {...response[lastkey][lastChildkey], ...obj}
                }
              }
            } else {
              lastChildkey = childColumn[i];
              if (futherChildColumns[i] == undefined) {
                const obj = {
                  [childColumn[i]] : school[i]
                }
                if (lastkey) {
                  response[lastkey] = { ...response[lastkey], ...obj };
                } else {
                  response[childColumn[i]] = school[i];
                }
              } else {
                const obj = {
                  [childColumn[i]] : {[futherChildColumns[i]] : school[i]}
                }
                if (lastkey) response[lastkey] = {...response[lastkey], ...obj}
              }
            }
          } else {
            lastChildkey = null;
            if (futherChildColumns[i] !== undefined) {
              if (childColumn[i] !== undefined) {
                const obj = {
                  [childColumn[i]] : {[futherChildColumns[i]] : school[i]}
                }
                response[parentColumn[i]] = obj;
                lastkey = parentColumn[i];
                lastChildkey = childColumn[i];
              } else {
                const obj = {
                  [futherChildColumns[i]] : school[i]
                }
              }
            } else {
              if (childColumn[i] !== undefined) {
                const obj = {
                  [childColumn[i]] : school[i]
                }
                response[parentColumn[i]] = obj;
                lastkey = parentColumn[i];
                lastChildkey = childColumn[i];
              } else {
                response[parentColumn[i]] = school[i];
                lastkey = parentColumn[i];
              }
            }
          }
        }
        if (response['School ID'] != undefined && response['School ID'] != null && response['School ID'] != '') finalData.push(response)
      }
      else {
        lastkey = null;
        lastChildkey = null;
      }
    })
    return finalData
  }

  /**
   * @author Manjunath
   */
  determineSchoolType(elementaryData: any[], highSchoolData: any[]) {
    // console.log(highSchoolData);
    
    if (elementaryData.length > 0) {
      elementaryData.forEach((elementarySchool: any) => {
        const highSchoolMatch = highSchoolData.find(
          (highSchool: any) => highSchool['School ID'] === elementarySchool['School ID']
        );
        if (highSchoolMatch) {
          elementarySchool.schoolType = 'BOTH';
          elementarySchool.isHighSchool = 0;
          highSchoolMatch.schoolType = 'BOTH';
          highSchoolMatch.isHighSchool = 1;
          this.elementrySchools.push(elementarySchool);
          this.highSchools.push(highSchoolMatch);
        } else {
          elementarySchool.schoolType = 'ELEMENTARY';
          elementarySchool.isHighSchool = 0;
          this.elementrySchools.push(elementarySchool);
        }
      });
    }
  
    if (highSchoolData.length > 0) {
      highSchoolData.forEach((highSchool: any) => {
        // Check if the high school already has a schoolType assigned
        if (!highSchool.schoolType) {
          highSchool.schoolType = 'HIGH';
          highSchool.isHighSchool = 1;
          this.highSchools.push(highSchool);
        }
      });
    }
  }

  /**
   * @author Manjunath
   */
  calculatePercentageCellValues(finalData: any[]) {
    const columnsToCalculate = ['Academic Performance', 'ARK Performance Summary']
    finalData.forEach((row) => {
      for (const key in row) {
        if (row.hasOwnProperty(key) && columnsToCalculate.includes(key)) {
          if (typeof row[key] === 'object') {
            for (const subKey in row[key]) {
              if (row[key].hasOwnProperty(subKey)) {
                if (typeof row[key][subKey] !== 'object') row[key][subKey] = Number((row[key][subKey] * 100).toFixed(2));
              }
            }
          } 
        }
      }
    });
    return finalData;
  }

  /**
   * @author Gopi
   * @uses to format the excel data
   */
  formatExcelData(excelData: any, type: String) {
    excelData.forEach((data: any, index: any) => {
      if (index != 0) {
        data.forEach((userData: any, userIndex: any) => {
          userIndex == 0 ? this.schoolNames.push({
            "index": index,
            "schoolName": userData
          }) : userIndex == 1 ? this.schoolIds.push({
            "index": index,
            "schoolId": userData
          }) : userIndex == 2 ? this.firstName.push({
            "index": index,
            "firstName": userData,
            "roleId": type === 'superAdmin' ? 1 : 'regional' ? 2 : 3
          }) : userIndex == 3 ? this.lastName.push({
            "index": index,
            "lastName": userData,
          }) : userIndex == 4 ? this.emails.push({
            "index": index,
            "email": userData,
          }) : userIndex == 5 ? this.alternateEmail.push({
            "index": index,
            "alternateEmail": ''
          }) : userIndex == 6 ? this.authProvider.push({
            "index": index,
            "authProvider": userData,
          }) : ''
        })
      }
    })
  }

  /**
  * @author Gopi
  * @uses to change the upload data
  */
  changeDataUpload() {
    if (this.uploadType === 'usersData') {
      this.enableUsersData = true;
      this.resetCSIPdata();
    }
    else {
      this.enableUsersData = false;
    }
  }

  /**
  * @author Manjunath
  * @uses to get enrollment summary from uploaded csip data file
  */
  getEnrollmentSummary(schoolData:any[], isHighSchool:boolean) {
    const regex = /FY\d{4}/;
    if (schoolData.length !== 0) {
      const enrollmentSummary = schoolData.reduce((acc: any[], obj: any) => {
        const matchingKeys = Object.keys(obj['Enrollment Summary']).filter((key) => regex.test(key));
        if (matchingKeys.length > 0) {
          const filteredObj: any = {};
          matchingKeys.forEach((key) => {
            const updatedKey = key.split('Y');
            const year = parseInt(updatedKey[1], 10);
            const value = obj['Enrollment Summary'][key];
            filteredObj['year'] = year;
            filteredObj['value'] = value == 'N/A' || value == 'n/a' || value == null ? null : value;
            if (obj.hasOwnProperty('School ID')) {
              filteredObj['schoolId'] = obj['School ID'];
            }
            if (obj['Enrollment Summary'].hasOwnProperty('Avg. Enrollment in K-2')) {
              filteredObj.avgEnrollment = obj['Enrollment Summary']['Avg. Enrollment in K-2'] == 'N/A' || obj['Enrollment Summary']['Avg. Enrollment in K-2'] == 'n/a' || obj['Enrollment Summary']['Avg. Enrollment in K-2'] == null ? null : obj['Enrollment Summary']['Avg. Enrollment in K-2'];
            }
            filteredObj.isHighSchool = isHighSchool ? 1 : 0;
            acc.push({...filteredObj});
          });
        }
        return acc;
      }, []);
      this.enrollmentSummary.length === 0 ? this.enrollmentSummary = enrollmentSummary : this.enrollmentSummary = [...this.enrollmentSummary, ...enrollmentSummary]
    }
  }

 /**
  * @author Manjunath
  * @uses to get blue ribbon calculator data from uploaded csip data file
  */
 getBlueRibbonCal(schoolData:any[]) {
  const regex = /BRC (Spring|Fall) \d{4}/;
  if (schoolData && schoolData.length != 0) {
    this.blueRibbonCalculator = schoolData.reduce((acc: any[], obj: any) => {
      const matchingKeys = obj['Blue Ribbon Calculator'] ? Object.keys(obj['Blue Ribbon Calculator']).filter(key => regex.test(key)) : [];
      if (matchingKeys.length > 0) {
        const filteredObj = matchingKeys.reduce((filtered, key) => {
          try {
            const updatedKey = key.split(' ')
            filtered[updatedKey[1]] = obj['Blue Ribbon Calculator'][key] as string;
            filtered['year'] = updatedKey[2]
          } catch {
            filtered[key] = obj['Blue Ribbon Calculator'][key] as string;
            filtered['year'] = key 
          }
          return filtered;
        }, {} as { [key: string]: string });
        if (obj.hasOwnProperty("School ID")) {
          filteredObj["schoolId"] = obj["School ID"] as string;
        }
        acc.push(filteredObj);
      }
      return acc;
    }, []);
  }
 }

 /**
  * @author Manjunath
  * @uses to slice data for a page
  */
 getPaginatedData(): any[] {
  const startIndex = (this.currentPage - 1) * this.itemsPerPage;
  const endIndex = startIndex + this.itemsPerPage;
  return this.csipDataForTable.slice(startIndex, endIndex);
 }

 /**
  * @author Manjunath
  * @uses to update page number
  */
 setPage(pageNumber: number) {
  this.currentPage = pageNumber;
 }

 /**
  * @author Manjunath
  * @uses to get numbers of pages
  */
 getPageNumbers(): number[] {
  const totalPages = Math.ceil(this.tableData[0]?.data.length / this.itemsPerPage);
  return Array(totalPages).fill(0).map((_, index) => index + 1);
 }

 /**
  * @author Manjunath
  * @uses to set css class in CSIP table
  */
 getCellClass(cellData: any): any {
  if (cellData === 'Organized') {
    return 'organized';
  } else if (cellData === 'Well-Organized') {
    return 'well-organized';
  } else if (cellData === 'Partially Organized') {
    return 'partially'
  } else if (cellData === 'Moderately Organized') {
    return 'moderate'
  } else if (cellData === 'Not Yet Organized') {
    return 'notyet'
  }
  return null;
 }

 /**
  * @author Manjunath
  * @uses to update cell value of CSIP data
  */
 updateCellValue(columnName: string, cellData: any): any {
  if (this.linkColumns.includes(columnName)) {
    if (cellData !== undefined && cellData != null && cellData != '') {
      return `<a href="${cellData}" target="_blank">link</a>`;
    } else {
      return cellData;
    };
  } else if (this.columnsToCalculate.includes(columnName)) {
    if (cellData !== undefined && cellData != null && cellData != '' && cellData != 'N/A') {
      return (Number(cellData)).toFixed(2);
    } else {
      return 0;
    };
  };
  return cellData;
 }

 /**
  * @author Manjunath
  * @uses to Reset CSIP data arrays
  */
 resetCSIPdata() {
  this.csipData = [];
  this.enrollmentSummary = [];
  this.blueRibbonCalculator = [];
  this.elementrySchools = [];
  this.highSchools = [];
 }

 /**
  * @author Manjunath
  * @uses to merge different arrays on schoolId
  */
 combineArrays(...arrays: any[][]): any[] {
  const combinedMap = new Map<number, any>();
  arrays.forEach(arr => {
    arr.forEach((obj: any) => {
      const schoolId = obj.schoolId;
      if (!combinedMap.has(schoolId)) {
        combinedMap.set(schoolId, { schoolId });
      }
      combinedMap.set(schoolId, { ...combinedMap.get(schoolId), ...obj });
    });
  });
  return Array.from(combinedMap.values());
}

/**
 * @author Manjunath
 * @uses to update key names to display in table
 */
updateKeys(schoolsArray: any[]): any[] {
  const keyConfig: { [key: string]: string } = {
    'schoolId': 'School Id',
    'name': 'School Name',
    'ELAGrowthPercent': 'ELA Growth',
    'MathGrowthPercent': 'Math Growth',
    'effectiveLeaders': 'Effective Leaders',
    'overAllPerformance': 'Overall Performance',
    'collaborativeTeachers': 'Collaborative Teachers',
    'involvedFamilies': 'Involved Families',
    'supportiveEnvironment': 'Supportive Environment',
    'ambitiousInstruction': 'Ambitious Instruction',
    'essentialsWeakOrVeryWeakRating':'5-Essentials Overall Rating = Weak or Very Weak',
    'essentialsLowResponseConcern':'Low Response Rate Concern',
    'lessThan3OutOf5EssentialsCategorizedAsStrong':'<  3 out of 5 Essentials categorized as Strong',
    'EnrollmentLessthan240':'Enrollment is <240',
    'enrollmentDropFromFY22-23 >5%':'Enrollment Drop from FY22-23 >5%',
    'AvgEnrollmentLessthan20':'Avg. enrollment is <20',
    'ELAAvgOrBetter':'ELA % of Avg or Better',
    'MathAvgOrBetter': 'Math % Avg or Better',
    'ProficencyHigh': '%ARK ProficencyHigh',
    'ProficencyModerate': '%ARK ProficencyModerate',
    'ProficencyLow': '%ARK ProficencyLow',
    'ELALessthanNWEA':'ELA Avg. Growth is less than NWEA target (100%)',
    'MathLessthanNWEA':'Math Avg. Growth is less than NWEA target (100%)',
    'ELAAvgOrBetterIndicator':'<50% of students are scoring Avg. or Better ELA',
    'MathAvgOrBetterIndicator':'<50% of students are scoring Avg. or Better Math',
    'studentsScoredLowOnARK':'>50% of students scored "Low" on ARK',
  };

  this.tableData.forEach(parentColumn => {
    parentColumn.childColumns = parentColumn.childColumns.map(oldColumn => keyConfig[oldColumn] || oldColumn);
  });

  return schoolsArray.map(school => {
    const updatedSchool: any = {};

    Object.keys(school).forEach(oldKey => {
      const newKey = keyConfig[oldKey];
      updatedSchool[newKey || oldKey] = school[oldKey];
    });

    return updatedSchool;
  });
}

/**
 * @author Manjunath
 * @uses to sort Enrollment Summary by year
 */
customSort(item:any) {
  return item.startsWith('FY') ? 0 : 1;
}

/**
 * @author Manjunath
 * @uses to format data to display in table CSIP data
 */
transformData(data: any): ParentColumn[] {
  const transformedData: ParentColumn[] = [];

  for (const parentColumnName in data) {
    if (data.hasOwnProperty(parentColumnName)) {
      let filteredChildColumns:any[] = [];
      let filteredData:any[] = [];
      try {
        const childColumns = Object.keys(data[parentColumnName][0]);
        if (childColumns.length > 0) {
          if (parentColumnName === 'School ID') {
            filteredChildColumns = ['schoolId', ...childColumns.filter(col => col !== 'schoolId')];
          } else if (parentColumnName === 'Enrollment Summary') {
            const nonSchoolIdColumns = childColumns.filter(col => col !== 'schoolId');
            const sortedColumns = nonSchoolIdColumns.sort((a, b) => this.customSort(a) - this.customSort(b) || a.localeCompare(b));
            filteredChildColumns = sortedColumns;
          } else {
            filteredChildColumns = childColumns.filter(col => col !== 'schoolId');
          }
    
          filteredData = data[parentColumnName].map((row: DataRow) => {
            const filteredRow: DataRow = {};
            for (const col of filteredChildColumns) {
              filteredRow[col] = row[col];
            }
            return filteredRow;
          });

          const parentColumn: ParentColumn = {
            name: parentColumnName,
            childColumns: filteredChildColumns,
            data: filteredData
          };
          transformedData.push(parentColumn);
        }
      } catch {
        let dummyText = `Data Not Found For ${parentColumnName}` 
        filteredChildColumns = [dummyText]
      }
    }
  }
  return transformedData;
}

 /**
  * @author Manjunath
  * @uses to update key Values for User Table
  */
updateKeysOfUserTable(userArray: any[]): any[] {
  const keyConfig: { [key: string]: string } = {
    'schoolId': 'School Id',
    'email' : 'Email',
    'firstName' : 'First Name',
    'lastName' : 'Last Name',
    'alternateEmail' : 'Alternate Email',
    'roleId' : 'Role Id',
    'isActive' : 'Active Status'
  }
  return userArray.map(user => {
    const updatedSchool: any = {};

    Object.keys(user).forEach(oldKey => {
      const newKey = keyConfig[oldKey];
      updatedSchool[newKey || oldKey] = user[oldKey];
    });
    return updatedSchool;
  });

}
 /**
  * @author Manjunath
  * @uses to get All Users
  */
 getAllUsers() {
  this._ajaxService.get('admin/getAllUsers').then((response: any) => {
    if (response.statusCode == 200) {
      this.userTableData = this.updateKeysOfUserTable(response.data);
    }
  });
 }

 /**
  * @author Manjunath
  * @uses to get data of every school
  */
 getSchoolData() {
  this._ajaxService.get(`admin/getAllSchoolsData?academicYearId=${this.academicYearId}`).then((response: any) => {
    if (response.statusCode == 200) {
      if (response.data['Schools'].length > 0) {
        this.tableData = this.transformData(response.data);
        const combinedArray: any[] = this.combineArrays(response.data['Schools'], 
                                                        response.data['School ID'],
                                                        response.data['Academic Performance'], 
                                                        response.data['5 Essentials Performance Summary'],
                                                        response.data['Enrollment Summary'],
                                                        response.data['ARK Performance Summary'],
                                                        response.data['Academic Performance Flags'],
                                                        response.data['5 Essentials Flags'],
                                                        response.data['Enrollment Flags'],
                                                        response.data['Catholic Identity'],
                                                        );
        this.csipDataForTable = this.updateKeys(combinedArray);
      }
    }
  })
 }

 async getAcademicYears() {
  this._ajaxService.get("dashboard/getAcademicYears").then(async (response: any) => {
    this.academicYears = response;
    const idx = response.length;
    this.academicYearId = response[idx - 1]['id']
    this.getSchoolData();
  });
}

createAcademicYear() {
  this.submitted = true;
  if (this.academicYearName.value.trim()) {
    if (!this.academicYearName.valid) return
    const request = {acdemicYear : this.academicYearName.value.trim()}
    this._ajaxService.post("admin/createAcademicYear", request).then(async (response: any) => {
      this.submitted = false;
      this.modalService.dismissAll()
      this.snackbar.open(`Academic Year Created Successfully`, "OK", { duration: 3000, panelClass: "warning-dialog" })
    }).catch(error => {
      let message = error.error.code == 'ER_DUP_ENTRY' ? 'Academic Year Already Exist.' : 'Something Went Wrong'
      this.snackbar.open(`${message}`, "OK", { duration: 3000, panelClass: "warning-dialog" })
    });
  } else {
    this.snackbar.open("Academic Year Can't be empty", "OK", { duration: 3000, panelClass: "warning-dialog" })
  }
}


toggleActiveStatus(user: any, event: Event) {
  const isChecked = (event.target as HTMLInputElement).checked;
  user['Active Status'] = isChecked;  

  this._ajaxService
    .post('admin/updateUserStatus', { userId: user.id, isActive: isChecked })
    .then((response: any) => {      
      if (response.statusCode !== 200) {
        user['Active Status'] = !isChecked;
      }

      if (response.statusCode == 200) {
        this.snackbar.open(`${response.message}`, "OK", { duration: 3000, panelClass: "success-dialog" })
      }

      
    });
}


}
